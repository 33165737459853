define("comorbidity/components/comorbidity/allergy-section-content/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! @glint-expect-error }}
  {{#let @patient.comorbiditiesAndAllergiesChangedJournalEvent as |je|}}
    {{#if (and je je.hasMedicatedAllergies)}}
      <ul data-test-list-allergies class="ui list">
        <li>
          Allergies :
          {{je.medicatedAllergies}}
        </li>
      </ul>
    {{else}}
      <div class="placeholder-label" data-test-default-allergies>
        {{t "dashboard.medical-synthesis.allergies-default-message"}}
      </div>
    {{/if}}
  {{/let}}
  */
  {
    "id": "5OpBKWWH",
    "block": "[[[44,[[30,1,[\"comorbiditiesAndAllergiesChangedJournalEvent\"]]],[[[41,[28,[37,2],[[30,2],[30,2,[\"hasMedicatedAllergies\"]]],null],[[[1,\"    \"],[10,\"ul\"],[14,\"data-test-list-allergies\",\"\"],[14,0,\"ui list\"],[12],[1,\"\\n      \"],[10,\"li\"],[12],[1,\"\\n        Allergies :\\n        \"],[1,[30,2,[\"medicatedAllergies\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"placeholder-label\"],[14,\"data-test-default-allergies\",\"\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"dashboard.medical-synthesis.allergies-default-message\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[2]]]],[\"@patient\",\"je\"],false,[\"let\",\"if\",\"and\",\"t\"]]",
    "moduleName": "comorbidity/components/comorbidity/allergy-section-content/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});