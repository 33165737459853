define("comorbidity/components/comorbidity/edition-form/icd10-dropdown/index.less", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "italic-text": "_italic-text_xyeuza",
    "suggestions": "_suggestions_xyeuza",
    "suggestion-item": "_suggestion-item_xyeuza",
    "description-text": "_description-text_xyeuza"
  };
});