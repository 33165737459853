define("ember-data-storefront/mixins/fastboot-adapter", ["exports", "@ember/object/mixin", "@ember/service", "rsvp", "ember-data-storefront/-private/utils/get-key", "@ember/application"], function (_exports, _mixin, _service, _rsvp, _getKey, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-new-mixins */
  /**
    This mixin adds fastboot support to your data adapter. It provides no
    public API, it only needs to be mixed into your adapter.
  
    ```js
    // app/adpaters/application.js
  
    import JSONAPIAdapter from 'ember-data/adapters/json-api';
    import FastbootAdapter from 'ember-data-storefront/mixins/fastboot-adapter';
  
    export default JSONAPIAdapter.extend(
      FastbootAdapter, {
  
      // ...
  
    });
    ```
  
    @class FastbootAdapter
    @public
  */
  var _default = _exports.default = _mixin.default.create({
    fastboot: (0, _service.inject)(),
    storefront: (0, _service.inject)(),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.fastboot.isFastBoot) {
        this.set('storefront.fastbootShoeboxCreated', new Date());
      }
    },
    ajax: function ajax(url, type) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var cachedPayload = this._getStorefrontBoxedQuery(type, url, options.data);
      var maybeAddToShoebox = this._makeStorefrontQueryBoxer(type, url, options.data);
      return cachedPayload ? (0, _rsvp.resolve)(JSON.parse(cachedPayload)) : this._super.apply(this, arguments).then(maybeAddToShoebox);
    },
    _makeStorefrontQueryBoxer: function _makeStorefrontQueryBoxer(type, url, params) {
      var fastboot = this.get('fastboot');
      var isFastboot = fastboot && fastboot.get('isFastBoot');
      var cache = this.get('storefront.fastbootDataRequests');
      return function (response) {
        if (isFastboot) {
          var key = (0, _getKey.shoeboxize)((0, _getKey.cacheKey)([type, url.replace(/^.*\/\/[^\/]+/, ''), params]));
          cache[key] = JSON.stringify(response);
        }
        return response;
      };
    },
    _getStorefrontBoxedQuery: function _getStorefrontBoxedQuery(type, url, params) {
      var payload;
      var fastboot = this.get('fastboot');
      var isFastboot = fastboot && fastboot.get('isFastBoot');
      var shoebox = fastboot && fastboot.get('shoebox');
      var box = shoebox && shoebox.retrieve('ember-data-storefront');
      var config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      var maxAgeMinutes = config.storefront ? config.storefront.maxAge : undefined;
      if (!isFastboot && box && box.queries && Object.keys(box.queries).length > 0) {
        var shouldUseShoebox = maxAgeMinutes === undefined || this.isDateValid(box.created, maxAgeMinutes);
        var key = (0, _getKey.shoeboxize)((0, _getKey.cacheKey)([type, url.replace(/^.*\/\/[^\/]+/, ''), params]));
        if (shouldUseShoebox) {
          payload = box.queries[key];
        }
        delete box.queries[key];
      }
      return payload;
    },
    isDateValid: function isDateValid(createdString, maxAgeMinutes) {
      return (new Date() - new Date(createdString)) / 1000 / 60 < maxAgeMinutes;
    }
  });
});