define("comorbidity/components/comorbidity/comorbidity-and-allergy-dashboard-row/index.less", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "row-container": "_row-container_1f7t5l",
    "grid": "_grid_1f7t5l",
    "for-mental-bipolar": "_for-mental-bipolar_1f7t5l"
  };
});