define("comorbidity/components/comorbidity/comorbidity-and-allergy-dashboard-row/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! prettier-ignore }}
  <Dashboard::PathologiesSection::Grid
    {{! @glint-expect-error }}
    local-class={{if (eq @patient.followUpReasonId "mental-bipolar") "grid for-mental-bipolar" "row-container"}}
  >
    <Comorbidity::ComorbidityAndAllergyDashboardRow::ComorbidityTitledBox
      {{! @glint-expect-error }}
      @patient={{@patient}}
    />
    <Comorbidity::ComorbidityAndAllergyDashboardRow::AllergyTitledBox
      {{! @glint-expect-error }}
      @patient={{@patient}}
    />
    {{! @glint-expect-error }}
    {{#if (eq @patient.followUpReasonId "mental-bipolar")}}
      {{! @glint-expect-error }}
      <AdherenceRiskPrediction::TitledBox @patient={{@patient}} />
      {{! @glint-expect-error }}
      <HospitalisationRiskPrediction::TitledBox @patient={{@patient}} />
    {{/if}}
    {{! @glint-expect-error }}
    <Transmission::TitledBox @patient={{@patient}} />
  </Dashboard::PathologiesSection::Grid>
  */
  {
    "id": "Xq8YK8UZ",
    "block": "[[[8,[39,0],[[16,0,[29,[[28,[37,1],[[52,[28,[37,3],[[30,1,[\"followUpReasonId\"]],\"mental-bipolar\"],null],\"grid for-mental-bipolar\",\"row-container\"]],[[\"from\"],[\"comorbidity/components/comorbidity/comorbidity-and-allergy-dashboard-row/index.less\"]]]]]]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,4],null,[[\"@patient\"],[[30,1]]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@patient\"],[[30,1]]],null],[1,\"\\n\"],[41,[28,[37,3],[[30,1,[\"followUpReasonId\"]],\"mental-bipolar\"],null],[[[1,\"    \"],[8,[39,6],null,[[\"@patient\"],[[30,1]]],null],[1,\"\\n\"],[1,\"    \"],[8,[39,7],null,[[\"@patient\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[8,[39,8],null,[[\"@patient\"],[[30,1]]],null],[1,\"\\n\"]],[]]]]]],[\"@patient\"],false,[\"dashboard/pathologies-section/grid\",\"local-class\",\"if\",\"eq\",\"comorbidity/comorbidity-and-allergy-dashboard-row/comorbidity-titled-box\",\"comorbidity/comorbidity-and-allergy-dashboard-row/allergy-titled-box\",\"adherence-risk-prediction/titled-box\",\"hospitalisation-risk-prediction/titled-box\",\"transmission/titled-box\"]]",
    "moduleName": "comorbidity/components/comorbidity/comorbidity-and-allergy-dashboard-row/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});